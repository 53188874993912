import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Category from './Category';

//List of all of the categories

const CategoryList = ({ activeCategory }) => {
  // Query to pull the posts
  const data = useStaticQuery(graphql`
    query CategoryListQuery {
      allMdx {
        edges {
          node {
            frontmatter {
              excerpt
              featImg
              cta
              title
              category
            }
            slug
          }
        }
      }
    }
  `);

  const edges = data.allMdx.edges;

  return (
    <div className="article-list content">
      {/* For every post that matches the active category, render the category component*/}
      {edges.map((post) => {
        const frontmatter = post.node.frontmatter;
        console.log('categoryList active:', activeCategory);
        console.log(
          'frontmatter category: ',
          frontmatter.category,
          frontmatter.category == activeCategory,
        );
        if (activeCategory == 'Все Темы') {
          return (
            <Category
              key={frontmatter.title}
              title={frontmatter.title}
              image={frontmatter.featImg}
              cta={frontmatter.cta}
              url={`/${post.node.slug}\/`}
            />
          );
        } else if (frontmatter.category == activeCategory) {
          return (
            <Category
              key={frontmatter.title}
              title={frontmatter.title}
              url={`/${post.node.slug}\/`}
            />
          );
        }
      })}
    </div>
  );
};

export default CategoryList;
