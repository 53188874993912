import React from 'react';
import Archive from '../components/Archives/Archive';
import Layout from '../components/layout';
import Seo from '../components/seo';

const Topics = () => {
  return (
    <Layout>
      <Seo
        title="Темы и вопросы"
        description="У вас есть проблемы в жизни? Просмотрите десятки тем и начните свой путь к более спокойной жизни прямо сейчас."
        pathname='temi'
      />
      <Archive />
    </Layout>
  );
};

export default Topics;
