import React, { useState, useEffect } from 'react';
import Separator from '../separator';
import { useStaticQuery, graphql } from 'gatsby';
import CategoryList from './CategoryList';
import './archive.scss';
import CatButton from './CatButton';
import { transliterate as tr, slugify } from 'transliteration';
const isBrowser = () => typeof window !== 'undefined';

const Archive = () => {
  // Query to pull the posts
  const data = useStaticQuery(graphql`
    query Archive {
      allMdx {
        edges {
          node {
            frontmatter {
              excerpt
              featImg
              cta
              title
              category
              buttonCategory
            }
            slug
          }
        }
      }
    }
  `);

  const categoryName = isBrowser() ? window.location.pathname : '';

  const edges = data.allMdx.edges;

  const categories = [];
  let activecat = 'Все Темы';

  // Places all categories in an array
  edges.map((post) => {
    const category = post.node.frontmatter.category[0];
    const buttonCategory = post.node.frontmatter.buttonCategory[0];

    let found = false;
    for (let i = 0; i < categories.length; i++) {
      if (categories[i].category == category) {
        found = true;
        break;
      }
    }
    if (!found) {
      categories.push({ category: category, buttonCategory: buttonCategory });
    }
  });
  const [activeCategory, setActiveCategory] = useState(activecat);

  categories.forEach((cat) => {
    if (categoryName.includes(slugify(cat.category))) {
      activecat = cat.category;
    }
  });
  useEffect(() => {
    setActiveCategory(activecat);
  }, []);

  // Set the category that is active, the category that the user clicks

  return (
    <section className="content-container archive">
      <section className="header">
        <h1>Темы и вопросы</h1>
      </section>
      <h2>Категории</h2>
      <div className="categories-wrapper">
        {/* Makes a list of all the categories that onClick changes with catgory is active*/}
        {categories.map((category) => {
          return (
            <a
              key={category.category}
              href={'/temi/' + slugify(category.category) + '/'}
            >
              <CatButton
                category={category.category}
                activecat={activeCategory}
                buttonCategory={category.buttonCategory}
              />
            </a>
          );
        })}
        <a href={'/temi'}>
          <CatButton
            category={'Все Темы'}
            activecat={activeCategory}
            buttonCategory={'Все темы'}
          />
        </a>
      </div>
      <Separator />
      <h3>{activeCategory}</h3>
      {/* Renders a list of all the posts from that category*/}
      <CategoryList activeCategory={activeCategory} />
      <Separator />
    </section>
  );
};

export default Archive;
