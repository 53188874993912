import React from 'react';
import { Link } from 'gatsby';
import { IoIosArrowForward } from 'react-icons/io';

// HTML Rendering of the posts per category
const Category = ({
  title = '',
  url = '',
  image = '',
  excerpt = '',
  cta = '',
}) => {
  return (
    <Link href={`${url}`}>
      <section className="box">
        {title}
        <div className="arrow">
          <IoIosArrowForward />
        </div>
      </section>
    </Link>
  );
};

export default Category;
