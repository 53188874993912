import React from 'react';

const CatButton = ({ category, activecat, buttonCategory }) => {
  // let catName = category.toLowerCase()
  // catName = catName.charAt(0).toUpperCase() + catName.slice(1)
  return (
    <button
      className={category == activecat ? 'activeBoxing' : 'boxing'}
      tabIndex={0}
      key={category}
      activecat={activecat}
      // onClick={(e) => {
      //   setactivecategory(category);
      // }}
    >
      {buttonCategory}
    </button>
  );
};

export default CatButton;
